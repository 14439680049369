import { regexpOptions } from '@/data/Regex'
import { RecipientValidateOptions } from '@/entity/validator/model/RecipientValidateOptions'
import { CheckingInvalidRecipient } from '@/presentation/recipient/model/CheckingInvalidRecipient'
import { allCountriesIso, allCountriesPhoneCodes } from '@/data/CountryPhoneCodes'
import { accountTypes } from '@/data/AccountType'
import { EUROPE_COUNTRIES } from '@/data/TransferConstants'
import { CurrencyEnum } from '@/enums/CurrencyEnum'
import { MasterCode } from '@/gateway/sheet/MasterCode'
import { CountryEnum, ReceiveMethodEnum } from '@/enums'
import { IdCardTypeEnum } from '@/enums/IdCardTypeEnum'

type RuleTypes = 'alwaysRequired' | 'pattern' | 'method'
type RuleValidateMethodType = {
  [key in RuleTypes]: (fieldName: string, option: RecipientValidateOptions, rule: RecipientRule) => boolean
}

interface RecipientRule {
  type: RuleTypes
  alwaysRequired?: boolean // default false
  optional?: boolean // default false
  trim?: boolean // default true
  pattern?: RegExp
  validate?: (fieldName: string, option: RecipientValidateOptions, rule: RecipientRule) => boolean
  message: string
}

export interface IGetMasterCodeOption {
  country: string
  method?: string
  masterCodeLabel?: string
  receiveCurrency?: string
}

export class RecipientRules {
  private static alwaysRequiredRule: RecipientRule = {
    type: 'alwaysRequired',
    message: 'this field is required'
  }

  private static fieldValidator(patternName: string, trim: boolean = false): RecipientRule {
    return {
      type: 'pattern',
      trim,
      pattern: regexpOptions[patternName],
      message: 'invalid pattern'
    }
  }

  private static getTrimmedField(
    fieldName: string,
    checkingRecipient: CheckingInvalidRecipient,
    isTrim: boolean | undefined
  ): string {
    const recipient = checkingRecipient as Dictionary<any>
    const hasField = !!recipient[fieldName]
    if (typeof recipient[fieldName] === 'number') recipient[fieldName] = Number(recipient[fieldName]).toString()
    const enableTrim = hasField && isTrim && typeof recipient[fieldName] === 'string'
    const trimmed = enableTrim ? recipient[fieldName].trim() : recipient[fieldName] || ''
    return trimmed
  }

  private static testAlwaysRequired(fieldName: string, option: RecipientValidateOptions, rule: RecipientRule): boolean {
    const trimmed = RecipientRules.getTrimmedField(fieldName, option.recipient, rule.trim)
    return !!trimmed
  }

  private static testPattern(fieldName: string, option: RecipientValidateOptions, rule: RecipientRule): boolean {
    const trimmed = RecipientRules.getTrimmedField(fieldName, option.recipient, rule.trim)
    if (rule.optional && !trimmed) return true
    if (!rule.pattern) return false
    const isPatternValid = rule.pattern.test(trimmed)
    return isPatternValid
  }

  private static testCustom(fieldName: string, option: RecipientValidateOptions, rule: RecipientRule): boolean {
    if (!rule.validate) return false
    return rule.validate(fieldName, option, rule)
  }

  private static testNumberCodeLength(
    fieldName: string,
    option: RecipientValidateOptions,
    country: keyof typeof CountryEnum,
    length: number
  ): boolean {
    const field = option.recipient[fieldName as keyof CheckingInvalidRecipient] as string
    if (!field || !field.length) return true
    if (option.country === country) {
      const isLength = field.toString().trim().length === length
      const isNumber = !isNaN(Number(field))
      return isLength && isNumber
    } else return true
  }

  public setRuleValidateMethods: RuleValidateMethodType = {
    alwaysRequired: RecipientRules.testAlwaysRequired,
    pattern: RecipientRules.testPattern,
    method: RecipientRules.testCustom
  }

  public static rules: Dictionary<Array<RecipientRule>> = {
    corps_id: [
      {
        type: 'alwaysRequired',
        message: 'this field is required'
      },
      {
        type: 'pattern',
        pattern: regexpOptions.corps_id,
        message: 'invalid pattern'
      }
    ],
    master_code: [
      {
        type: 'method',
        trim: false,
        validate: (fieldName, validateOption) => {
          if (!MasterCode.hasMasterCodes(validateOption.country)) return false
          const getMasterCodeOption: IGetMasterCodeOption = {
            country: validateOption.country,
            masterCodeLabel: validateOption.recipient.master_code,
            receiveCurrency: validateOption.recipient.base_currency
          }
          return !!MasterCode.getMasterCode(getMasterCodeOption)
        },
        message: 'invalid master_code'
      }
    ],
    name_first: [RecipientRules.alwaysRequiredRule, RecipientRules.fieldValidator('name')],
    name_middle: [RecipientRules.fieldValidator('name')],
    name_last: [RecipientRules.fieldValidator('name')],
    address_line1: [RecipientRules.fieldValidator('address_line1')],
    account_number: [
      RecipientRules.fieldValidator('account_number'),
      {
        type: 'method',
        validate: (fieldName, option) => {
          if (!option.recipient.account_number) return true
          if (option.country === 'US') {
            return option.recipient.account_number.length <= 17
          } else if (option.country === 'AU') {
            return option.recipient.account_number.length <= 9
          } else if (option.country === 'GB') {
            return option.recipient.account_number.length <= 11
          } else return true
        },
        message: 'invalid format'
      }
    ],
    bsb_code: [RecipientRules.fieldValidator('bsb_code')],
    transit_code: [RecipientRules.fieldValidator('transit_code')],
    bank_branch: [
      {
        type: 'method',
        validate: (fieldName, option) => {
          return RecipientRules.testNumberCodeLength('bank_branch', option, 'JP', 3)
        },
        message: '3 length number format only'
      }
    ],
    routing_number: [
      {
        type: 'method',
        validate: (fieldName, option) => {
          return RecipientRules.testNumberCodeLength('routing_number', option, 'US', 9)
        },
        message: '9 length number format only'
      }
    ],
    nation: [
      {
        type: 'method',
        validate: (fieldName, option) => {
          option.recipient.nation = option.recipient.nation?.toUpperCase()
          return option.recipient.nation ? allCountriesIso.includes(option.recipient.nation) : true
        },
        message: 'invalid nation'
      }
    ],
    phone_code: [
      {
        type: 'method',
        validate: (fieldName, option) => {
          const phoneCode = option.recipient.phone_code
          if (!phoneCode) return false
          const isNumericPhoneCode = !!Number(phoneCode)
          if (!isNumericPhoneCode) option.recipient.phone_code = phoneCode.toString().toUpperCase()
          return isNumericPhoneCode
            ? allCountriesPhoneCodes.includes(Number(phoneCode))
            : allCountriesIso.includes(phoneCode.toString().toUpperCase())
        },
        message: 'invalid phone_code'
      }
    ],
    phone: [
      {
        type: 'method',
        validate: (fieldName, option) => {
          const phone = Number(option.recipient.phone)
          return !!phone
        },
        message: 'invalid phone number'
      }
    ],
    base_currency: [
      {
        type: 'method',
        validate: (fieldName, option) => {
          if (!option.recipient.base_currency) return false
          option.recipient.base_currency = option.recipient.base_currency.toUpperCase() as keyof typeof CurrencyEnum
          const receiveCurrency = option.recipient.base_currency
          if (!option.remittanceBase) {
            if (option.isAnalogueCorp) return ['USD'].includes(receiveCurrency)
            else return false
          }

          let supportedReceiveCurrencies
          
          if(option.method === ReceiveMethodEnum.USD_ANYWHERE){
            supportedReceiveCurrencies = ['USD']
          }
          else if(option.isAnalogueCorp) {
            supportedReceiveCurrencies = [...option.remittanceBase.supported_currency, 'USD']
          }
          else{
            supportedReceiveCurrencies = option.remittanceBase.supported_currency
          }

          return supportedReceiveCurrencies.includes(receiveCurrency)
        },
        message: 'invalid receive currency'
      }
    ],
    id_card_type: [
      {
        type: 'method',
        validate: (fieldName, option) => {
          if (!option.recipient?.id_card_type) return true
          const inputIdCardTypeName = option.recipient.id_card_type.toString().toUpperCase()
          return Object.keys(IdCardTypeEnum).some(type => {
            const enumTypeName = type.toString().toUpperCase().replace(/_/gi, ' ')
            return enumTypeName === inputIdCardTypeName
          })
        },
        message: 'invalid id card type'
      }
    ],
    id_card_number: [RecipientRules.fieldValidator('id_card_number')],
    account_type: [
      {
        type: 'method',
        validate: (fieldName, option) => {
          option.recipient.account_type = option.recipient.account_type?.toUpperCase()
          const accountType = option.recipient.account_type
          if ([...EUROPE_COUNTRIES, 'HK', 'US'].includes(option.country)) {
            if (!accountType) return false
            const hasAccountTypes = Object.keys(accountTypes).includes(accountType.toUpperCase())
            return hasAccountTypes
          }
          return true
        },
        message: 'invalid account type'
      }
    ],
    swift: [
      {
        type: 'method',
        validate: (fieldName, option) => {
          const swift = option.recipient.swift
          if (EUROPE_COUNTRIES.includes(option.country)) return !!swift
          return true
        },
        message: 'swift code not matched'
      },
      RecipientRules.fieldValidator('swift')
    ],
    sort_code: [
      {
        type: 'method',
        validate: (fieldName, option) => {
          return RecipientRules.testNumberCodeLength('sort_code', option, 'GB', 6)
        },
        message: '6 length number format only'
      }
    ],
    iban: [
      {
        type: 'method',
        validate: (fieldName, option) => {
          const iban = option.recipient.iban
          const country = option.country
          if (EUROPE_COUNTRIES.includes(country)) {
            if (!iban) return false
            const regexString = regexpOptions[`iban_${country}`]
            if (regexString) {
              return new RegExp(regexString).test(iban)
            }
          }
          return true
        },
        message: 'iban code not matched'
      }
    ],
    ifsc: [RecipientRules.fieldValidator('ifsc')],
    birth_date: [
      {
        type: 'method',
        validate: (fieldName, option) => {
          const birthDate = RecipientRules.getTrimmedField('birth_date', option.recipient, true)
          const replacedBirthDate = birthDate.replace(/-/g, '')
          return regexpOptions.birth_date.test(replacedBirthDate)
        },
        message: 'invalid birth date format'
      }
    ],
    corresponding_bank_swift: [
      {
        type: 'method',
        validate: (fieldName, option) => {
          const correspondingBankSwift = option.recipient.corresponding_bank_swift ?? ''
          if (correspondingBankSwift === '') return true
          return regexpOptions.swift.test(correspondingBankSwift)
        },
        message: 'invalid pattern'
      }
    ],
  }
}
